<template>
  <div id="app">
    <div class="container">
      <div class="new-item">
        <div @click="goBack()" id="back" class="fa fa-angle-left"></div>
        <span class="name">{{$t("addItem.newItem") }}</span>
      </div>
      <div class="content">
        <form autocomplete="off">
          <label v-if="!this.isSystemValid" for="system" class="invalid_value">{{ $t("addItem.enterSystem") }}</label>
          <label v-else for="system" class="active">{{ $t("addItem.system") }}</label>
          <div @mouseover="showItems = true" @mouseleave="showItems = false">
            <input id="system" type="text" v-model="item.system">
          </div>

          <div class="manufacturerItems" v-if="showItems && this.manufacturerName">
            <table>
              <tbody>
              <tr v-for="item of selectedManufacturerItems" :key="item.id">
                <td class="nameTd">{{ item.system }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <label for="manufacturer" :class="this.isManufacturerValid ? 'active': 'invalid_value'">
            {{ $t(!this.isManufacturerValid ? "addItem.selectManufacturer" : "addItem.manufacturer") }}
            <i class="fa fa-briefcase" v-if="context !== 'manufacturers'" @click="createManufacturer"></i>
          </label>
          <div>
            <input type="text" id="manufacturer"
                   v-model="manufacturerName"
                   list="selectManufacturer"
                   @change="getItemsByManufacturerId"
                   :disabled="context === 'manufacturers'">
            <datalist id="selectManufacturer" disabled>
              <option v-for="manufacturer in this.manufacturers" :key="manufacturer.id"
                      v-bind:value="manufacturer.name">
                {{ manufacturer.name }}
              </option>
            </datalist>
          </div>

          <label for="mountType" class="active">{{ $t("addItem.mountType") }}</label>
          <div>
            <input type="text" id="mountType" v-model="item.mountType" list="selectMountTypes">
            <datalist id="selectMountTypes" disabled>
              <option v-for="mountType in this.mountTypes" :key="mountType" v-bind:value="mountType">
                {{ mountType }}
              </option>
            </datalist>
          </div>

          <label for="width" class="active">
            <span v-if="!this.isWidthValid" class="invalid_value">{{ $t("addItem.enterWidth") }}</span>
            <span v-else>{{ $t("addItem.width") }}</span>
          </label>
          <input id="width" type="text" v-model.number="item.width">

          <label for="depth" class="active">
            <span v-if="!this.isDepthValid" class="invalid_value">{{ $t("addItem.enterDepth") }}</span>
            <span v-else>{{ $t("addItem.depth") }}</span>
          </label>
          <input id="depth" type="text" v-model.number="item.depth">

          <label for="thickness" class="active">
            <span v-if="!this.isThicknessValid" class="invalid_value">{{ $t("addItem.enterThickness") }}</span>
            <span v-else>{{ $t("addItem.thickness") }}</span>
          </label>
          <input id="thickness" type="text" v-model.number="item.thickness">

          <label for="l1" class="active">
            <span v-if="!this.isL1Valid" class="invalid_value">{{ $t("addItem.enterHorizontalStep") }}</span>
            <span v-else>{{ $t("addItem.horizontalStep") }}</span>
          </label>
          <input id="l1" type="text" v-model.number="item.horizontalStep">

          <label for="l2" class="active">
            <span v-if="!this.isL2Valid" class="invalid_value">{{ $t("addItem.enterVerticalStep") }}</span>
            <span v-else>{{ $t("addItem.verticalStep") }}</span>
          </label>
          <input id="l2" type="text" v-model.number="item.verticalStep">

          <div id="subCon">
            <button v-on:click="createItem" id="create" type="button" class="btn waves-effect waves-light">
              {{ $t("addItem.create") }}<i class="fa fa-plus right"></i></button>
          </div>
        </form>
      </div>
      <div class="addImage">
        <input type="file" accept="image/*" @change="fileUpload" style="display: none" ref="fileInput">
        <div ><img v-show="!this.upload" class="uploadedImage"
                   src="../../../public/uploadImg.png"
                   @click="$refs.fileInput.click()"
                   v-bind:alt="$t('singleTicket.imageAlt')">
          <div class="image" v-if="this.upload"><RingLoader color="red"/></div>
        </div>
        <div class="forSpan" v-show="!this.upload">
          <div class="fa fa-upload spanDiv" @click="$refs.fileInput.click()">
            <span style="font-family: BloggerSans,sans-serif"> {{ $t("singleItem.uploadImage") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ManufacturersBoard from "../manufacturer/ManufacturersBoard";
import axios from "axios";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';
import Link from "../utils/Link";
import ui from "../utils/ui";
import $ from 'jquery';
import { RingLoader } from '@saeris/vue-spinners'


export default {
  name: 'AddItem',
  data() {
    return {
      item: {
        manufacturer: undefined,
      },
      showItems: false,
      manufacturers: [],
      manufacturerName: '',
      selectedManufacturerItems: [],
      mountTypes: [],
      isSystemValid: true,
      isManufacturerValid: true,
      isWidthValid: true,
      isDepthValid: true,
      isThicknessValid: true,
      isL2Valid: true,
      isL1Valid: true,
      upload: false,
      context: ""
    };
  },

  mounted() {
    this.context = window.localStorage.getItem("headerTitle");
    if (this.fillItemFromStorage()) {
      $('.uploadedImage').attr('src', this.item.imageUrl)
    }

    this.getMountTypes().then(mountTypes => {
      this.mountTypes = mountTypes;
    })

    ManufacturersBoard.methods.getManufacturers().then( manufacturers => {
      this.manufacturers = manufacturers;
    });
  },

  components:{
    RingLoader
  },

  methods: {
    createManufacturer() {
      this.concatFields(this.item);
      this.$router.push({ name: 'newManufacturer'});
    },

    concatFields(item) {
      let savedItem = JSON.stringify(item);
      window.localStorage.setItem("savedItem", savedItem);
    },

    fillItemFromStorage() {
      let savedItem = undefined;

      if (this.context === 'tickets' || this.context === 'items' || this.context === 'manufacturers') {
        savedItem = window.localStorage.getItem("savedItem");
        if (savedItem) {
          window.localStorage.removeItem("savedItem");
          this.item = JSON.parse(savedItem)
          this.manufacturerName = this.item.manufacturer;
          this.manufacturer = this.manufacturers.find((el) => el.name === this.manufacturerName);
        } else {
          if (this.context === 'tickets') {
            savedItem = window.localStorage.getItem("ticketData");
            if (savedItem) {
              this.item = JSON.parse(savedItem)
              this.manufacturerName = this.item.manufacturer;
              this.manufacturer = this.manufacturers.find((el) => el.name === this.manufacturerName);
            }
          } else if (this.context === 'manufacturers') {
            savedItem = window.localStorage.getItem("savedManufacturer");
            if (savedItem) {
              this.manufacturerName = savedItem;
              this.manufacturer = this.manufacturers.find((el) => el.name === this.manufacturerName);
            }
          }
        }
      }

      return (savedItem != null);
    },

    fileUpload(event) {
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append('file', this.selectedFile)
      const headers = {
        "Authorization": Link.methods.getToken(),
      }
      if (this.selectedFile !== undefined) {
        this.upload = true;
        axios.post(Link.methods.getFilePhotoUploadUrl(), fd, {headers}).then(res => {
          this.item.imageUrl = res.data;
          $('.uploadedImage').attr('src', this.item.imageUrl).css('animation', 'none');
          this.upload = false;
        }).catch(()=>{
          this.upload = false;
        })
      }
    },

    createItem() {
      const isValid = this.checkFields();
      if (!isValid) {
        return;
      }

      this.formatNumericFields();

      // convert this.item.manufacturer from string (name) to object (Manufacturer):
      let manufacturer = this.manufacturers.find((el) => el.name === this.manufacturerName);
      if (manufacturer == null) {
        this.createToast(this.$t('addItem.incorrectManufacturer', [this.manufacturerName]), "error");
        return
      }
      this.item.manufacturer = manufacturer;

      const headers = Link.methods.getHeaders()
      if (this.item.ticketId) {
        new Promise((resolve) => {
          axios.post(
              Link.methods.getItemFromTicketCreateUrl(this.item.ticketId),
              this.item,
              {headers}
          ).then(() => {
            this.createToast(this.$t("addItem.toastCreated", [this.item.system]), "success");
          }).then(() => {
            this.goBack(-2);
          }).catch(err => {
            this.createToast(this.$t('addItem.incorrectManufacturer', [err.response.status]), "error");
          });
          resolve();
        });
      } else {
        new Promise((resolve) => {
          axios.post(Link.methods.getItemsCreateUrl(), this.item, {headers}).then(() => {
            this.createToast(this.$t("addItem.toastCreated", [this.item.system]), "success");
          }).then(() => {
            this.goBack();
          }).catch(err => {
            this.createToast(this.$t('addItem.incorrectManufacturer', [err.response.status]), "error");
          });
          resolve();
        });
      }
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    goBack(n) {
      window.localStorage.removeItem("savedItem");
      window.localStorage.removeItem("ticketData");
      window.localStorage.removeItem("savedManufacturer");

      if (n == null) {
        n = -1
      }

      return this.$router.go(n)
    },

    formatNumericFields() {
      this.item.witdh = ui.methods.fmt(this.item.width);
      this.item.depth = ui.methods.fmt(this.item.depth);
      this.item.thickness = ui.methods.fmt(this.item.thickness);
      this.item.horizontalStep = ui.methods.fmt(this.item.horizontalStep);
      this.item.verticalStep = ui.methods.fmt(this.item.verticalStep);
    },

    checkFields() {
      this.isSystemValid = !!this.item.system;
      this.isManufacturerValid = !!this.item.manufacturer;
      this.isWidthValid = !ui.methods.isEmptyNumber(this.item.width);
      this.isDepthValid = !ui.methods.isEmptyNumber(this.item.depth) || this.item.depth == null || !isNaN(this.item.depth);
      this.isThicknessValid = !ui.methods.isEmptyNumber(this.item.thickness) || this.item.thickness == null || !isNaN(this.item.thickness);
      this.isL1Valid = !ui.methods.isEmptyNumber(this.item.horizontalStep);
      this.isL2Valid = !ui.methods.isEmptyNumber(this.item.verticalStep);

      return this.isSystemValid
          && this.isManufacturerValid
          && this.isWidthValid
          && this.isL2Valid
          && this.isL1Valid
          && this.isDepthValid
          && this.isThicknessValid;
    },

    getItemsByManufacturerId() {
      this.item.manufacturer = this.manufacturers.find( (el) => (el.name === this.manufacturerName))
      if (this.item.manufacturer != null) {
        const headers = Link.methods.getHeaders();
        axios.get(
          Link.methods.getItemsByManufacturer(this.item.manufacturer.id),
          {headers}
        ).then(res => {
          this.selectedManufacturerItems = res.data;
        });
      }
    },

    getMountTypes() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getMountTypes(),
        {headers}
      ).then(res => {
        return res.data;
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../../public/materialize-src/sass/materialize.scss';
@import "../../../public/styles/vars";
@import '../../../public/styles/singleItem';

.uploadedImage {
  max-width: 30vh;
  max-height: 60vh;
  top: 50%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  display: inline-block;
}

.uploadedImage:hover {
  opacity: 80%;
  /*height: 1vh;*/
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

#create > i {
  color:  white;
  padding: 0;
}

#create {
  width: -webkit-fill-available;
  background: $second-color;
}

.image {
  margin-top: 100px
}

.invalid_value {
  color: red;
  font-size: 13px;
}

.spin{
  display: flex;
  justify-content: center;
  margin-right: 20px;
}

.forSpan{
  /*margin-right: 20%;*/
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
}

.manufacturerItems {
  z-index: 9;
  width: 200px;
  position: absolute;
  margin-left: 320px;
  top: 150px;
}

tbody{
  display: block;
  max-height: 480px;
  min-height: max-content;
  width: 100%;
  position:relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

tbody::-webkit-scrollbar {
  display: none;
}

thead {
  display: block;
  line-height: 20px;
  font-size: 18px;
  color: white;
  background-color: #535353;
}

tr {
  display: flex;
  width: 100%;
  line-height: 20px;
  color: black;
}

th, td{
  display: block;
}

tr{
  background-color: gray;
}

th{
  line-height: 36px;
  text-align-last: center;
}

td{
  text-align-last: left;
  font-size: 12px;
}

.nameTd {
  width: 100%;
}


table{
  width: 100%;
}

</style>
